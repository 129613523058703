import React, { useState } from "react";
import { Mail, Lock, User, Phone, Send } from "lucide-react";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    username: "",
    password: "",
    email: "",
    mobile: "",
    otp: "",
    contactMethod: "email", // Default to email
  });
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");

    const contactInfo =
      formData.contactMethod === "email"
        ? { email: formData.email }
        : { mobile: formData.mobile };

    try {
      const response = await fetch("http://43.204.244.247:8005/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          password: formData.password,
          full_name: formData.full_name,
          ...contactInfo,
        }),
      });

      if (response.ok) {
        setOtpSent(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Registration failed. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch("http://43.204.244.247:8005/verify-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          otp: formData.otp,
        }),
      });

      if (response.ok) {
        navigate("/signin");
      } else {
        const errorData = await response.json();
        setError(
          errorData.message || "OTP verification failed. Please try again."
        );
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-xl shadow-md">
        <h1
          className="text-3xl font-bold text-center"
          style={{ color: "#24786D" }}
        >
          Sign Up
        </h1>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <form
          onSubmit={otpSent ? handleVerifyOTP : handleRegister}
          className="space-y-4"
        >
          {!otpSent && (
            <>
              <div className="space-y-2">
                <label
                  htmlFor="full_name"
                  className="text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="relative">
                  <User
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    id="full_name"
                    name="full_name"
                    type="text"
                    placeholder="Enter your full name"
                    value={formData.full_name}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                    style={{ borderColor: "#24786D" }}
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="username"
                  className="text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <div className="relative">
                  <User
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Choose a username"
                    value={formData.username}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                    style={{ borderColor: "#24786D" }}
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <Lock
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Choose a password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                    style={{ borderColor: "#24786D" }}
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Contact Method
                </label>
                <div className="flex space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="contactMethod"
                      value="email"
                      checked={formData.contactMethod === "email"}
                      onChange={handleChange}
                      className="form-radio text-green-600"
                    />
                    <span className="ml-2">Email</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="contactMethod"
                      value="mobile"
                      checked={formData.contactMethod === "mobile"}
                      onChange={handleChange}
                      className="form-radio text-green-600"
                    />
                    <span className="ml-2">Mobile</span>
                  </label>
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="relative">
                  <Mail
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                    style={{ borderColor: "#24786D" }}
                    required={formData.contactMethod === "email"}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label
                  htmlFor="mobile"
                  className="text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <div className="relative">
                  <Phone
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    id="mobile"
                    name="mobile"
                    type="tel"
                    placeholder="Enter your mobile number"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                    style={{ borderColor: "#24786D" }}
                    required={formData.contactMethod === "mobile"}
                  />
                </div>
              </div>
            </>
          )}

          {otpSent && (
            <div className="space-y-2">
              <label
                htmlFor="otp"
                className="text-sm font-medium text-gray-700"
              >
                Enter OTP
              </label>
              <div className="relative">
                <Send
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={18}
                />
                <input
                  id="otp"
                  name="otp"
                  type="text"
                  placeholder="Enter OTP"
                  value={formData.otp}
                  onChange={handleChange}
                  className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
                  style={{ borderColor: "#24786D" }}
                  required
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            className="w-full text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
            style={{ backgroundColor: "#24786D" }}
          >
            {otpSent ? "Verify OTP" : "Register"}
          </button>
        </form>

        <div className="text-center">
          <span className="text-sm text-gray-600">
            Already have an account?{" "}
          </span>
          <a
            href="/signin"
            className="text-sm font-medium hover:underline"
            style={{ color: "#24786D" }}
          >
            Sign in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
