import React, { useState, useEffect } from "react";
import { ChevronDown, MapPin, Star, Plus, Minus, Search } from "lucide-react";

const JobPostingForm = ({ onAdd, onSubmitAll }) => {
  const initialFormState = {
    description: "",
    designation: "",
    location: "",
    experienceRequired: "",
    date: new Date().toLocaleDateString("en-GB"),
    responsibilities: "",
    qualifications: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const [allJobPostings, setAllJobPostings] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAuthHeaders = () => {
    const token = sessionStorage.getItem("authToken");
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (searchTerm) {
        fetchSuggestions();
      } else {
        setSuggestions([]);
      }
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchTerm]);

  const fetchSuggestions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `http://43.204.244.247:8005/questions/additional/search?query=${searchTerm}`,
        {
          headers: getAuthHeaders(),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
    setIsLoading(false);
  };

  const handleSelectSuggestion = (question) => {
    setQuestions([...questions, question]);
    setSearchTerm("");
    setSuggestions([]);
  };

  const handleAddNewQuestion = () => {
    if (searchTerm.trim() !== "") {
      setQuestions([...questions, searchTerm]);
      setSearchTerm("");
      setSuggestions([]);
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const handleSubmitQuestion = async (question) => {
    try {
      const response = await fetch(
        "http://43.204.244.247:8005/questions/additional",
        {
          method: "POST",
          headers: getAuthHeaders(),
          body: JSON.stringify({
            id: Date.now(),
            question: question,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Additional question submitted:", result);
      return true;
    } catch (error) {
      console.error("Error submitting additional question:", error);
      return false;
    }
  };

  const handleSubmitQuestions = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback("");

    const nonEmptyQuestions = questions.filter((q) => q.trim() !== "");

    if (nonEmptyQuestions.length === 0) {
      setFeedback("Please add at least one question before submitting.");
      setIsSubmitting(false);
      return;
    }

    let allSuccessful = true;

    for (const question of nonEmptyQuestions) {
      const success = await handleSubmitQuestion(question);
      if (!success) {
        allSuccessful = false;
      }
    }

    setIsSubmitting(false);
    if (allSuccessful) {
      setFeedback("All questions submitted successfully!");
    } else {
      setFeedback("Some questions failed to submit. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback("");

    const newJobPosting = {
      questions: {
        description: formData.description,
        designation: formData.designation,
        location: formData.location,
        experienceRequired: formData.experienceRequired,
        date: formData.date,
        responsibilities: formData.responsibilities,
        qualifications: formData.qualifications,
      },
      additional_questions: questions.map((question, index) => ({
        [`question`]: question,
      })),
    };

    try {
      const response = await fetch("http://43.204.244.247:8005/job_posting", {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify(newJobPosting),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Job posting submitted successfully:", result);

      onAdd(newJobPosting);
      setAllJobPostings((prevPostings) => [...prevPostings, newJobPosting]);
      setFormData(initialFormState);
      setQuestions([]);
      setFeedback("Job posting submitted successfully!");
    } catch (error) {
      console.error("Error submitting job posting:", error);
      setFeedback("Failed to submit job posting. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitAll = () => {
    onSubmitAll(allJobPostings);
    setAllJobPostings([]);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-6 sm:p-8 space-y-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-[#24786D] mb-2">
              Create Job Posting
            </h1>
            <p className="text-gray-600">
              Fill in the details below to create a new job posting
            </p>
          </div>

          <form onSubmit={handleAdd} className="space-y-8">
            {/* Description Section */}
            <div className="space-y-4">
              <label className="block">
                <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                  Description
                </span>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                  rows="4"
                  placeholder="Enter detailed job description..."
                />
              </label>
            </div>

            {/* Grid layout for shorter fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Designation */}
              <div>
                <label className="block">
                  <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                    Designation
                  </span>
                  <div className="relative">
                    <select
                      name="designation"
                      value={formData.designation}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    >
                      <option value="">Select Designation</option>
                      <option value="Java Developer">Java Developer</option>
                      <option value="Frontend Developer">
                        Frontend Developer
                      </option>
                      <option value="Full Stack Developer">
                        Full Stack Developer
                      </option>
                    </select>
                    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
                  </div>
                </label>
              </div>

              {/* Location */}
              <div>
                <label className="block">
                  <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                    Location
                  </span>
                  <div className="relative">
                    <input
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder="Enter location"
                      className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    />
                    <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </label>
              </div>

              {/* Experience Required */}
              <div className="md:col-span-2">
                <label className="block">
                  <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                    Experience Required
                  </span>
                  <div className="relative">
                    <select
                      name="experienceRequired"
                      value={formData.experienceRequired}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    >
                      <option value="">Select Experience</option>
                      <option value="0-1 years">0-1 years</option>
                      <option value="1-3 years">1-3 years</option>
                      <option value="3-5 years">3-5 years</option>
                      <option value="5+ years">5+ years</option>
                    </select>
                    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
                  </div>
                </label>
              </div>
            </div>

            {/* Responsibilities and Qualifications */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block">
                  <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                    Responsibilities
                  </span>
                  <textarea
                    name="responsibilities"
                    value={formData.responsibilities}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    rows="4"
                    placeholder="Enter responsibilities (one per line)"
                  />
                </label>
              </div>

              <div>
                <label className="block">
                  <span className="text-xl font-semibold text-[#24786D] mb-2 block">
                    Qualifications
                  </span>
                  <textarea
                    name="qualifications"
                    value={formData.qualifications}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    rows="4"
                    placeholder="Enter qualifications (one per line)"
                  />
                </label>
              </div>
            </div>

            {/* Additional Questions Section */}
            <div className="bg-gray-50 rounded-xl p-6 space-y-6">
              <h2 className="text-2xl font-semibold text-[#24786D] mb-4">
                Additional Questions
              </h2>

              <div className="space-y-4">
                <div className="relative">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                    placeholder="Search or add new questions"
                  />
                  {isLoading && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#CD853F]"></div>
                    </div>
                  )}
                </div>

                {suggestions.length > 0 && (
                  <ul className="bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                    {suggestions.map((result) => (
                      <li
                        key={result.id}
                        className="p-3 hover:bg-gray-100 cursor-pointer transition duration-200"
                        onClick={() => handleSelectSuggestion(result.question)}
                      >
                        {result.question}
                      </li>
                    ))}
                  </ul>
                )}

                <button
                  type="button"
                  onClick={handleAddNewQuestion}
                  className="w-full bg-[#CD853F] text-white py-3 px-4 rounded-lg hover:bg-[#24786D] transition duration-300 flex items-center justify-center gap-2"
                >
                  <Plus size={20} />
                  Add New Question
                </button>

                {questions.map((question, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <input
                      type="text"
                      value={question}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                      className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition duration-200"
                      placeholder={`Question ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                      className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                      aria-label="Remove question"
                    >
                      <Minus size={20} />
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={handleSubmitQuestions}
                  className="w-full bg-[#24786D] text-white py-3 px-4 rounded-lg hover:bg-[#CD853F] transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Questions"}
                </button>
              </div>
            </div>

            {/* Submit Buttons */}
            <div className="space-y-4">
              <button
                type="submit"
                className="w-full bg-[#CD853F] text-white py-3 px-4 rounded-lg hover:bg-[#24786D] transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed text-lg font-semibold"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit Job Posting"}
              </button>

              {allJobPostings.length > 0 && (
                <button
                  onClick={handleSubmitAll}
                  className="w-full bg-[#24786D] text-white py-3 px-4 rounded-lg hover:bg-[#CD853F] transition duration-300 text-lg font-semibold"
                  type="button"
                >
                  Submit All Job Postings
                </button>
              )}
            </div>
          </form>

          {/* Feedback Message */}
          {feedback && (
            <div
              className={`mt-4 p-4 rounded-lg ${
                feedback.includes("successfully")
                  ? "bg-green-100 text-green-800 border border-green-200"
                  : "bg-red-100 text-red-800 border border-red-200"
              }`}
            >
              {feedback}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPostingForm;
