import React, { useState, useEffect } from "react";
import { MapPin, Calendar, Briefcase, Search, ChevronDown } from "lucide-react";
import { useNavigate } from "react-router-dom";

const JobListings = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDesignation, setFilterDesignation] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await fetch("http://43.204.244.247:8005/job_posting", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch jobs");

      const data = await response.json();
      setJobs(data);
    } catch (err) {
      setError("Failed to load jobs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleApply = (jobId) => {
    navigate(`/jobs/apply/${jobId}`);
  };

  const filteredJobs = jobs.filter((job) => {
    const matchesSearch =
      searchTerm === "" ||
      job.questions.designation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      job.questions.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const matchesDesignation =
      filterDesignation === "" ||
      job.questions.designation === filterDesignation;

    const matchesLocation =
      filterLocation === "" || job.questions.location === filterLocation;

    return matchesSearch && matchesDesignation && matchesLocation;
  });

  const uniqueDesignations = [
    ...new Set(jobs.map((job) => job.questions.designation)),
  ];
  const uniqueLocations = [
    ...new Set(jobs.map((job) => job.questions.location)),
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#24786D]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-red-100 text-red-700 p-4 rounded-lg shadow animate-fade-in">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section with Animation */}
        <div className="mb-8 text-center transform transition-all duration-500 hover:scale-105">
          <h1 className="text-4xl md:text-5xl font-bold text-[#24786D] mb-4 animate-fade-in">
            Find Your Next Opportunity
          </h1>
          <p className="mt-2 text-gray-600 text-lg md:text-xl animate-fade-in-delay">
            Discover and apply to exciting positions that match your skills
          </p>
        </div>

        {/* Search and Filter Section */}
        <div className="mb-8 bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
          <div className="relative mb-6">
            <Search
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 transition-colors duration-300 ${
                isSearchFocused ? "text-[#24786D]" : "text-gray-400"
              }`}
            />
            <input
              type="text"
              placeholder="Search for jobs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative group">
              <select
                value={filterDesignation}
                onChange={(e) => setFilterDesignation(e.target.value)}
                className="w-full pl-4 pr-10 py-3 border-2 border-gray-200 rounded-lg appearance-none focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300 group-hover:border-[#24786D]"
              >
                <option value="">All Designations</option>
                {uniqueDesignations.map((designation, index) => (
                  <option key={index} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-[#24786D] transition-colors duration-300" />
            </div>

            <div className="relative group">
              <select
                value={filterLocation}
                onChange={(e) => setFilterLocation(e.target.value)}
                className="w-full pl-4 pr-10 py-3 border-2 border-gray-200 rounded-lg appearance-none focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300 group-hover:border-[#24786D]"
              >
                <option value="">All Locations</option>
                {uniqueLocations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-[#24786D] transition-colors duration-300" />
            </div>
          </div>
        </div>

        {/* Job Listings */}
        <div className="space-y-6">
          {filteredJobs.length === 0 ? (
            <div className="text-center py-12 bg-white rounded-xl shadow-lg animate-fade-in">
              <p className="text-gray-500 text-xl">
                No jobs found matching your criteria
              </p>
            </div>
          ) : (
            filteredJobs.map((job, index) => (
              <div
                key={job.id}
                className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 animate-fade-in"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="p-6 md:p-8">
                  <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                    <div className="flex-grow">
                      <h2 className="text-2xl font-semibold text-[#24786D] mb-3 hover:text-[#1a5a52] transition-colors duration-300">
                        {job.questions.designation}
                      </h2>

                      <div className="flex flex-wrap gap-4 text-gray-600 mb-4">
                        <div className="flex items-center group">
                          <MapPin className="w-5 h-5 mr-2 group-hover:text-[#24786D] transition-colors duration-300" />
                          <span className="group-hover:text-[#24786D] transition-colors duration-300">
                            {job.questions.location}
                          </span>
                        </div>
                        <div className="flex items-center group">
                          <Briefcase className="w-5 h-5 mr-2 group-hover:text-[#24786D] transition-colors duration-300" />
                          <span className="group-hover:text-[#24786D] transition-colors duration-300">
                            {job.questions.experienceRequired}
                          </span>
                        </div>
                        <div className="flex items-center group">
                          <Calendar className="w-5 h-5 mr-2 group-hover:text-[#24786D] transition-colors duration-300" />
                          <span className="group-hover:text-[#24786D] transition-colors duration-300">
                            {job.questions.date}
                          </span>
                        </div>
                      </div>

                      <div className="prose prose-sm text-gray-600 mb-4 line-clamp-3 hover:line-clamp-none transition-all duration-300">
                        {job.questions.description}
                      </div>

                      <div className="space-y-2">
                        <div>
                          <span className="font-medium text-gray-700">
                            Qualifications:{" "}
                          </span>
                          <span className="text-gray-600">
                            {job.questions.qualifications}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-auto">
                      <button
                        onClick={() => handleApply(job.id)}
                        className="w-full md:w-auto px-8 py-3 bg-[#24786D] text-white rounded-lg hover:bg-[#1a5a52] transform hover:scale-105 transition-all duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-[#24786D]"
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>

                  {job.status && (
                    <div className="mt-4">
                      <span
                        className={`inline-flex px-3 py-1 rounded-full text-sm font-medium transition-all duration-300 ${
                          job.status === "active"
                            ? "bg-green-100 text-green-800 hover:bg-green-200"
                            : "bg-yellow-100 text-yellow-800 hover:bg-yellow-200"
                        }`}
                      >
                        {job.status.charAt(0).toUpperCase() +
                          job.status.slice(1)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default JobListings;
