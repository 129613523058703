import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  Send,
  AlertCircle,
  Building,
  Phone,
  Mail,
  User,
  Clock,
  DollarSign,
  FileText,
  Briefcase,
  Calendar,
} from "lucide-react";

// Add custom animation keyframes
const styles = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slideDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    75% { transform: translateX(10px); }
  }

  .animate-fadeIn {
    animation: fadeIn 0.5s ease-out;
  }

  .animate-slideDown {
    animation: slideDown 0.5s ease-out;
  }

  .animate-slideUp {
    animation: slideUp 0.5s ease-out;
  }

  .animate-shake {
    animation: shake 0.5s ease-in-out;
  }
`;

const JobApplicationForm = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    experience: "",
    currentCompany: "",
    noticePeriod: "",
    expectedSalary: "",
    coverLetter: "",
    answers: {},
  });

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `http://43.204.244.247:8005/job_posting/${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch job details");

        const data = await response.json();
        setJobDetails(data);

        // Initialize answers object with additional questions
        if (data.additional_questions) {
          const initialAnswers = {};
          data.additional_questions.forEach((q) => {
            initialAnswers[q.question] = "";
          });
          setFormData((prev) => ({
            ...prev,
            answers: initialAnswers,
          }));
        }
      } catch (err) {
        setError("Failed to load job details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    // Add style tag for animations
    const styleTag = document.createElement("style");
    styleTag.textContent = styles;
    document.head.appendChild(styleTag);

    fetchJobDetails();

    // Cleanup
    return () => {
      document.head.removeChild(styleTag);
    };
  }, [jobId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAnswerChange = (question, value) => {
    setFormData((prev) => ({
      ...prev,
      answers: {
        ...prev.answers,
        [question]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      const payload = {
        answers: formData.answers,
        status: "submitted",
        job_posting_id: parseInt(jobId),
        application_details: {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          experience: formData.experience,
          currentCompany: formData.currentCompany,
          noticePeriod: formData.noticePeriod,
          expectedSalary: formData.expectedSalary,
          coverLetter: formData.coverLetter,
        },
      };

      const response = await fetch(
        "http://43.204.244.247:8005/job_application",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Failed to submit application");

      setSuccess(true);
      setTimeout(() => {
        navigate("/jobs");
      }, 2000);
    } catch (err) {
      setError("Failed to submit application. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const InputField = ({
    icon: Icon,
    label,
    name,
    type = "text",
    value,
    required = true,
  }) => (
    <div className="group">
      <label className="flex items-center text-sm font-medium text-gray-700 mb-1 group-hover:text-[#24786D] transition-colors">
        <Icon className="w-4 h-4 mr-2" />
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleInputChange}
        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300 hover:border-[#24786D] transform hover:-translate-y-0.5 bg-white"
        required={required}
      />
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#24786D]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8 animate-fadeIn">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate("/jobs")}
          className="group flex items-center text-[#24786D] mb-6 hover:text-[#1a5a52] transition-all duration-300 transform hover:-translate-x-1"
        >
          <ChevronLeft className="w-5 h-5 mr-1 transition-transform duration-300 group-hover:scale-125" />
          <span className="font-medium">Back to Jobs</span>
        </button>

        <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 mb-8 transition-all duration-300 hover:shadow-xl">
          <div className="border-b border-gray-200 pb-6 mb-6">
            <h1 className="text-3xl font-bold text-[#24786D] mb-2 animate-slideDown">
              Apply for {jobDetails?.questions.designation}
            </h1>
            <div className="flex flex-col md:flex-row md:items-center gap-4 text-gray-600 text-lg animate-slideDown delay-100">
              <span className="inline-flex items-center">
                <Building className="w-5 h-5 mr-2 text-[#24786D]" />
                Location: {jobDetails?.questions.location}
              </span>
              <span className="hidden md:inline">|</span>
              <span className="inline-flex items-center">
                <Clock className="w-5 h-5 mr-2 text-[#24786D]" />
                Experience: {jobDetails?.questions.experienceRequired}
              </span>
            </div>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg flex items-center animate-shake">
              <AlertCircle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 border border-green-200 text-green-700 rounded-lg animate-slideDown">
              <div className="flex items-center">
                <div className="animate-bounce mr-2">🎉</div>
                Application submitted successfully! Redirecting...
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InputField
                icon={User}
                label="Full Name"
                name="fullName"
                value={formData.fullName}
              />
              <InputField
                icon={Mail}
                label="Email"
                name="email"
                type="email"
                value={formData.email}
              />
              <InputField
                icon={Phone}
                label="Phone Number"
                name="phone"
                type="tel"
                value={formData.phone}
              />
              <InputField
                icon={Briefcase}
                label="Years of Experience"
                name="experience"
                value={formData.experience}
              />
              <InputField
                icon={Building}
                label="Current Company"
                name="currentCompany"
                value={formData.currentCompany}
                required={false}
              />
              <InputField
                icon={Calendar}
                label="Notice Period"
                name="noticePeriod"
                value={formData.noticePeriod}
              />
              <InputField
                icon={DollarSign}
                label="Expected Salary"
                name="expectedSalary"
                value={formData.expectedSalary}
              />
            </div>

            <div className="group">
              <label className="flex items-center text-sm font-medium text-gray-700 mb-1 group-hover:text-[#24786D] transition-colors">
                <FileText className="w-4 h-4 mr-2" />
                Cover Letter
              </label>
              <textarea
                name="coverLetter"
                value={formData.coverLetter}
                onChange={handleInputChange}
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300 hover:border-[#24786D] resize-none bg-white"
                required
              />
            </div>

            {jobDetails?.additional_questions && (
              <div className="space-y-6 animate-slideUp">
                <h2 className="text-xl font-semibold text-[#24786D] flex items-center">
                  <AlertCircle className="w-5 h-5 mr-2" />
                  Additional Questions
                </h2>
                {jobDetails.additional_questions.map((q, index) => (
                  <div key={index} className="group">
                    <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-[#24786D] transition-colors">
                      {q.question}
                    </label>
                    <input
                      type="text"
                      value={formData.answers[q.question] || ""}
                      onChange={(e) =>
                        handleAnswerChange(q.question, e.target.value)
                      }
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24786D] focus:border-transparent transition-all duration-300 hover:border-[#24786D] transform hover:-translate-y-0.5 bg-white"
                      required
                    />
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-end pt-6">
              <button
                type="submit"
                disabled={submitting}
                className="group flex items-center bg-[#24786D] text-white px-8 py-3 rounded-lg hover:bg-[#1a5a52] transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none disabled:hover:shadow-none"
              >
                {submitting ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
                    Submitting...
                  </>
                ) : (
                  <>
                    Submit Application
                    <Send className="ml-2 w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
