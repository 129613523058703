import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import { Menu, X } from "lucide-react";
import JobSearch from "./Views/JobSearch";
import JobPostingForm from "./Views/JobPostForm";
import SignInPage from "./Views/Signin";
import SignUpPage from "./Views/SignUpPage";
import JobListings from "./Views/JobListing";
import JobApplicationForm from "./Views/JobApplicationForm";

const MainApp = () => {
  const [jobData, setJobData] = useState(null);
  const [allJobPostings, setAllJobPostings] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize isLoggedIn based on presence of authToken in sessionStorage
    return !!sessionStorage.getItem("authToken");
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Check auth status when component mounts
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleFormSubmit = (data) => {
    setJobData(data);
    setAllJobPostings((prevPostings) => [...prevPostings, data]);
  };

  const handleSubmitAll = (postings) => {
    console.log("All job postings submitted:", postings);
  };

  const handleLogin = (token) => {
    sessionStorage.setItem("authToken", token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authToken");
    setIsLoggedIn(false);
  };

  const ProtectedRoute = ({ children }) => {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      return <Navigate to="/signin" replace />;
    }
    return children;
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <nav className="bg-gradient-to-r from-[#24786D] to-[#1a5a52] shadow-lg">
          {/* Rest of the navigation code remains the same */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex flex-1 justify-between">
                <div className="flex items-center">
                  <Link
                    to="/jobs"
                    className="flex-shrink-0 flex items-center group"
                  >
                    <span className="text-2xl font-bold text-white transition-all duration-300 group-hover:text-[#CD853F]">
                      JobBoard
                    </span>
                  </Link>
                  {isLoggedIn && (
                    <div className="hidden sm:ml-8 sm:flex sm:space-x-8">
                      <Link
                        to="/search"
                        className="text-gray-100 inline-flex items-center px-3 py-2 text-sm font-medium transition-all duration-300 hover:bg-white/10 rounded-md"
                      >
                        Job Search
                      </Link>
                      <Link
                        to="/post"
                        className="text-gray-100 inline-flex items-center px-3 py-2 text-sm font-medium transition-all duration-300 hover:bg-white/10 rounded-md"
                      >
                        Post a Job
                      </Link>
                    </div>
                  )}
                </div>

                <div className="hidden sm:flex sm:items-center">
                  {isLoggedIn ? (
                    <button
                      onClick={handleLogout}
                      className="text-white hover:bg-white/10 px-4 py-2 rounded-md text-sm font-medium transition-all duration-300 border border-transparent hover:border-white/20"
                    >
                      Sign Out
                    </button>
                  ) : (
                    <Link
                      to="/signin"
                      className="text-white hover:bg-white/10 px-4 py-2 rounded-md text-sm font-medium transition-all duration-300 border border-transparent hover:border-white/20"
                    >
                      Sign In
                    </Link>
                  )}
                </div>
              </div>

              <div className="flex items-center sm:hidden">
                <button
                  onClick={toggleMobileMenu}
                  className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-white/10 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {isMobileMenuOpen ? (
                    <X className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Menu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div
            className={`sm:hidden transition-all duration-300 ease-in-out ${
              isMobileMenuOpen
                ? "max-h-48 opacity-100"
                : "max-h-0 opacity-0 overflow-hidden"
            }`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 bg-gradient-to-b from-[#1a5a52] to-[#24786D]">
              {isLoggedIn && (
                <>
                  <Link
                    to="/search"
                    className="block px-3 py-2 text-base font-medium text-white hover:bg-white/10 rounded-md transition-all duration-300"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Job Search
                  </Link>
                  <Link
                    to="/post"
                    className="block px-3 py-2 text-base font-medium text-white hover:bg-white/10 rounded-md transition-all duration-300"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Post a Job
                  </Link>
                </>
              )}
              {isLoggedIn ? (
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-3 py-2 text-base font-medium text-white hover:bg-white/10 rounded-md transition-all duration-300"
                >
                  Sign Out
                </button>
              ) : (
                <Link
                  to="/signin"
                  className="block px-3 py-2 text-base font-medium text-white hover:bg-white/10 rounded-md transition-all duration-300"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign In
                </Link>
              )}
            </div>
          </div>
        </nav>

        <Routes>
          <Route
            path="/jobs"
            element={
              <ProtectedRoute>
                <JobListings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/signin"
            element={<SignInPage onLogin={handleLogin} />}
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <JobSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute>
                <JobSearch />
              </ProtectedRoute>
            }
          />
          <Route path="/jobs/apply/:jobId" element={<JobApplicationForm />} />
          <Route
            path="/post"
            element={
              <ProtectedRoute>
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h1 className="text-3xl font-bold text-[#24786D] mb-4">
                    Job Posting Form
                  </h1>
                  <JobPostingForm
                    onAdd={handleFormSubmit}
                    onSubmitAll={handleSubmitAll}
                  />
                </div>
              </ProtectedRoute>
            }
          />
          <Route path="/signup" element={<SignUpPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default MainApp;
